import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";

import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";

class App extends React.Component {
  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route
                  path={`${match.url}/dashboard`}
                  component={asyncComponent(() => import("./routes/Business"))}
                />
                <Route
                  path={`${match.url}/stats`}
                  component={asyncComponent(() => import("./routes/Stats"))}
                />
                <Route
                  path={`${match.url}/reporting`}
                  component={asyncComponent(() => import("./routes/StatsAll"))}
                />
                <Route
                  path={`${match.url}/crm`}
                  component={asyncComponent(() => import("./routes/Crm"))}
                />
                <Route
                  path={`${match.url}/carte`}
                  component={asyncComponent(() => import("./routes/Map"))}
                />
                <Route
                  path={`${match.url}/admin`}
                  component={asyncComponent(() => import("./routes/Admin"))}
                />
                <Route
                  path={`${match.url}/calendar`}
                  component={asyncComponent(() => import("./routes/Calendar"))}
                />
                <Route
                  path={`${match.url}/ordres`}
                  component={asyncComponent(() => import("./routes/Ordres"))}
                />
                <Route
                  path={`${match.url}/users`}
                  component={asyncComponent(() => import("./routes/Users"))}
                />
                <Route
                  path={`${match.url}/campaigns`}
                  component={asyncComponent(() => import("./routes/Campaigns"))}
                />
                <Route
                  path={`${match.url}/customers`}
                  component={asyncComponent(() => import("./routes/Customers"))}
                />
                <Route
                  path={`${match.url}/positions`}
                  component={asyncComponent(() => import("./routes/Position"))}
                />
                <Route
                  path={`${match.url}/techpaid`}
                  component={asyncComponent(() => import("./routes/TechPaid"))}
                />

                <Route
                  path={`${match.url}/stpaid`}
                  component={asyncComponent(() =>
                    import("./routes/SousTraitePaid")
                  )}
                />

                <Route
                  path={`${match.url}/mon-profil`}
                  component={asyncComponent(() => import("./routes/Profile"))}
                />

                <Route
                  path={`${match.url}/factures`}
                  component={asyncComponent(() => import("./routes/Invoices"))}
                />

                <Route
                  path={`${match.url}/invoices`}
                  component={asyncComponent(() => import("./routes/Invoices"))}
                />

                <Route
                  path={`${match.url}/litige`}
                  component={asyncComponent(() =>
                    import("./routes/Litigation")
                  )}
                />

                <Route
                  path={`${match.url}/paye`}
                  component={asyncComponent(() => import("./routes/TechPaid"))}
                />

                <Route
                  component={asyncComponent(() =>
                    import("components/Error404")
                  )}
                />
              </Switch>
            </div>
            <Footer />
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  const { authUser } = auth;
  return { drawerType, navigationStyle, horizontalNavPosition, authUser };
};
export default withRouter(connect(mapStateToProps)(App));
